import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

export default {
  name: "maintenanceUserAdd",
  mixins: [mixins],
  data() {
    return {
      roles: [],
      edit: false,
      carTypeList: [],
      formModel: {
        id: null,
        userName: '',
        userPhone: '',
        userID: '',
        userRoles: [],
        userRole: ''
      },
      formRules: {
        userName: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }],
        userPhone: [{
          required: true,
          message: '请输入电话号码',
          trigger: 'blur'
        }, {
          min: 11,
          message: '电话号码不能少于11位',
          trigger: 'blur'
        }],
        userID: [{
          required: true,
          message: '请输入身份证号码',
          trigger: 'blur'
        }, {
          min: 18,
          message: '身份证号码不能少于18位',
          trigger: 'blur'
        }],
        userRoles: [{
          required: true,
          message: '请选择角色',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    getRole() {
      this.$api.business.dictionary.getItemByCode({
        code: 'yw-role'
      }).then(res => {
        if (res.code === 200) {
          this.roles = res.data;
        }
      });
    },
    submitForm() {
      1;
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const callBack = res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
            } else {
              ElMessage.error(res.message);
            }
          };
          this.formModel.userRole = this.formModel.userRoles.toString();
          if (this.edit) {
            this.$api.business.maintenanceUser.update(this.formModel).then(callBack);
          } else {
            this.$api.business.maintenanceUser.create(this.formModel).then(callBack);
          }
        }
      });
    }
  },
  beforeMount() {
    this.getRole();
    if (this.$route.query.id) {
      this.edit = true;
      const loading = ElLoading.service();
      this.$api.business.maintenanceUser.detail({
        id: this.$route.query.id
      }).then(res => {
        if (res.code === 200) {
          this.formModel = res.data;
          if (this.formModel.userRole) {
            this.formModel.userRoles = this.formModel.userRole.split(',');
          }
        } else if (res.code === 121601) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    }
  }
};